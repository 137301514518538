import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Trade'),
    icon: 'Swap',
    href: '/swap',
    showItemsOnMobile: false,
    items: [
      {
        label: t('Exchange'),
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        href: '/liquidity',
      },
    ],
  },
  {
    label: t('Farms'),
    href: '/farms',
    icon: 'Earn',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Stake'),
    href: '/staking',
    icon: 'Earn',
    showItemsOnMobile: false,
    items: [],
  },
  // {
  //   label: t('Referral'),
  //   href: '/referral',
  //   icon: 'Earn',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  {
    label: 'Launchpad',
    href: '/launchpad',
    icon: 'Trophy',
    showItemsOnMobile: false,

      items: [

    ],
  },


  {
    label: 'Chain Bridge',
    href: '/bridge',
    icon: 'Bridge',
    showItemsOnMobile: false,
    items: [

    ],
  },
  {
    label: 'Buy Presale',
    href: '/buy',
    icon: 'Ifo',
    showItemsOnMobile: false,
    items: [
      
    ],
  },
]

export default config
